import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import notFoundStyles from '../styles/notFound.module.scss'

export default () => (
  <Layout>
    <Head title="Page not found!" />
    <div className={notFoundStyles.content}>
      <p>Page not found (404) !</p>
    </div>
  </Layout>
)
